<template>
<v-app>
    <div>
        <v-toolbar
                dense
                color="#587E96"
                dark
                extended
                extension-height="120"
                flat
        >
            <v-toolbar-title  slot="extension" style="margin-left: 8px !important" >                               

                <h4>Mensalidades em Aberto</h4> 
                <h6>Contrato: {{id_c_s.Referencia}}</h6>
                <h6>Data: {{ id_c_s.DataContrato | formatDate }} </h6>


                <v-layout row >
                    <v-flex wrap>
                        <v-btn outline small fab color="white" to="/home" class="outine-2">
                              <i class="fa fa-home fa-2x" ></i>
                        </v-btn>
                    </v-flex>
                   
                    
                </v-layout>
            </v-toolbar-title>
        </v-toolbar>

        <v-container class="mx-auto" >
        
        <div class="text-center" style="text-align: center;margin-top:100px">
            <v-progress-circular v-if="onLoadDate === false"
                :size="70"
                :width="7"
                color="primary"
                indeterminate>
            </v-progress-circular>  
          </div>


          <v-data-table   v-if="onLoadDate" style="padding-left:32px; background-color: white; font-size: 22px;"
                :headers="headers" :hide-headers="isMobile" :hide-default-footer="true"   hide-default-footer disable-pagination
                :items="parcelas"   hide-rows-per-page="true" 
                class="elevation-1">
                <template slot="items" slot-scope="props">
                  
                
                    <br/>
                        <span><b>Tipo: </b></span> {{ props.item.TipoParcela }} <br/>
                        <span><b>Vencimento: </b></span> {{ props.item.DataVencimento | formatDate }} <br/>
                        <span><b>Valor Original: </b></span> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.item.ValorOriginal) }} <br/>
                        <span><b>Encargos: </b></span> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.item.Encargos) }} <br/>
                        <span><b>Valor a Pagar: </b></span> {{ new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(props.item.ValorTotal) }} <br/>

                        <br/>
                        <span>    
                          <v-btn style="width: 400px;height: 80px;font-size: 22px;padding-left: 8px;"  color="success" @click="openPagamento(props.item)" >
                             <span style="padding-right: 10px;"> 
                            
                              Pagar no crédito
                            </span>

                            <i class="fa fa-credit-card fa-2x"></i>
                          </v-btn>                                
                        </span>
                        <br/>

                
                  <br/>
              </template>
            </v-data-table>
            <v-card-actions v-if="onLoadDate" > 
            <v-spacer></v-spacer>
                <v-btn
                    color="primary"
                    text
                    @click="finalizar" 
                >
                    SAIR
                </v-btn>
            </v-card-actions>
        

</v-container>
</div>

<v-dialog
v-model="dialog_card"
persistent
max-width="600px"
>
<v-card>
<v-card-title>
  <span class="headline">{{this.msg_processando}}</span>
</v-card-title>
<v-card-text>

<div class="text-center" style="text-align:center;margin-top:10px">
    <v-progress-circular v-if="processando"
                    :size="70"
                    :width="7"
                    color="primary"
                    indeterminate>
    </v-progress-circular>  
</div>    

<v-container grid-list-md v-if="processando === false">  
 <v-form ref="form" lazy-validation> 
    <v-layout wrap>
    
        <v-flex xs12>
            <v-text-field  v-model="dados_pagamento.cpf_pagador" v-mask="'###.###.###-##'"  Required  label="CPF do Pagador*" :rules="[v => !!v || 'Campo Obrigatório']"    box></v-text-field>
        </v-flex>

        <v-flex xs12>
            <v-text-field v-model="dados_pagamento.nome_cartao" label="Nome impresso no cartão*"  Required :rules="[v => !!v || 'Campo Obrigatório']"    box></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
            <v-text-field v-model="dados_pagamento.numero_cartao" label="Número*" v-mask="'#### #### #### ####'" Required  :rules="[v => !!v || 'Campo Obrigatório']"  v-on:change="ler_bandeira"   box></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
            <v-text-field value="" v-model="dados_pagamento.bandeira" label="Bandeira" readonly :rules="[v => !!v || 'Campo Obrigatório']"  Required box></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
            <v-select v-model="dados_pagamento.mes_vencimento" label="Mês de Vencimento*"  :rules="[v => !!v || 'Campo Obrigatório']" Required :items="meses"  box></v-select>
        </v-flex>

        <v-flex xs12 sm6>
            <v-text-field v-model="dados_pagamento.ano_vencimento" label="Ano de Vencimento*"   minlength="4" Required v-mask="'####'" :rules="[rules_ano_cartao.required, rules_ano_cartao.counter]"  box></v-text-field>
        </v-flex>

        <v-flex xs12 sm6>
            <v-text-field v-model="dados_pagamento.cvv" label="CVV*" :rules="[v => !!v || 'Campo Obrigatório']" Required  box></v-text-field>
        </v-flex>

    </v-layout>
    </v-form>
</v-container> 
</v-card-text>
<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn
    color="primary"
    text flat :disabled="processando"
    @click="dialog_card = false"
  >
    Sair
  </v-btn>
  <v-btn :loading="processando" :disabled="processando"
    color="primary"
    text
    @click="pagar"
  >
    Confirmar
  </v-btn>
</v-card-actions>
</v-card>
</v-dialog>

<v-dialog
v-model="dialog_barras"
persistent
max-width="400px"
>
<v-card>
<v-card-title>
  <span class="headline">Código de barras</span>
</v-card-title>

<div class="text-center" style="text-align: center;">

    <v-card-text v-if="this.parcela.LinhaDigitavel">
            <h2>{{this.parcela.LinhaDigitavel}}</h2>
    </v-card-text>

</div>    
<v-card-actions>
  <v-spacer></v-spacer>
  <v-btn
    color="primary"
    text flat
    @click="dialog_barras = false"
  >
    Sair
  </v-btn>
</v-card-actions>
</v-card>
</v-dialog>



<v-dialog 
transition="dialog-bottom-transition" 
max-width="600"  v-model="resposta_processamento">

<template>
  <v-card>
  <v-toolbar color="success" dark> <span class="headline">Informação</span></v-toolbar>

    <v-card-text v-if="this.resposta_cielo.sucesso_pagamento" >
      <div id="pnl">

        <br/>
           <h3> <v-icon large color="green darken-2">mdi-check</v-icon> Parabéns seu Pagamento foi Aprovado</h3> <br/>
           <h4>PAGAMENTO DE MENSALIDADE - ONLINE</h4>
            <h4>Valor: R$ {{resposta_cielo.valor_pago}} </h4>
            <h4>Data: {{ new Date()}} </h4>
            <h4>Nº da Transação: {{resposta_cielo.tid}}</h4><br/>
            <h4>Mensagem: {{resposta_cielo.msg_cielo}}</h4>
      </div>  
    </v-card-text>


    <v-card-actions class="justify-end" v-if="this.resposta_cielo.sucesso_pagamento">
       <v-btn
        outlined
        color="orange"
        @click="sendDataToAndroid"
      >Imprimir <v-icon right dark> mdi-printer
</v-icon></v-btn>
      <v-btn
        outlined
        color="success" @click="finalizar"
      >Finalizar e Sair<v-icon right dark> mdi-check
</v-icon></v-btn>
    </v-card-actions>



    <v-card-text v-if="resposta_cielo.sucesso_pagamento == false">
      <br/>
          <h3> <v-icon large color="red darken-2">mdi-close</v-icon> Pagamento não foi concluido, verifique os dados do cartão e tente novamente.</h3> <br/>
            <h4>Mensagem: {{this.resposta_cielo.msg_cielo}}</h4>

    </v-card-text>
   

       <v-card-actions class="justify-end" v-if="resposta_cielo.sucesso_pagamento == false">
      <v-btn
        outlined
        color="success" @click="resposta_processamento = false"
      >OK<v-icon right dark> mdi-check
</v-icon></v-btn>
    </v-card-actions>



  </v-card>
</template>
</v-dialog>







</v-app>
</template>

<script>
import { http } from '../../services/config'
import authHeader from '../../services/auth-header';
import { mapGetters, mapActions } from 'vuex'
import ConsultaBandeira from '../../services/bandeira';

export default {


data() {
    return {
           
           id_c_s: {},
           resposta_processamento: false,
           msg_processando: 'Pagamento com Cartão de Crédito',
           resposta_cielo:{

                            tid: '',
                            dataRecebimento: '',
                            msg_cielo: '',
                            sucesso_pagamento: false,
                            valor_pago: 0,
                        },
           
           processando: false,
           parcela: '',
           onLoadDate: false,
           dialog_card: false,
           dialog_barras: false,
           showload: false,
          isMobile: true,
           headers: [

                    { text: 'Tipo', sortable: false },
                    { text: 'Vencimento', sortable: false },
                    { text: 'Valor Original', sortable: false },
                    { text: 'Encargos', sortable: false },
                    { text: 'Valor a Pagar', sortable: false }
                    ],
            parcelas: [],
            item_parcela: '',



            model: { parametros: {
                    IdDocumentoReceber: 0,
                    QuantidadeParcelas: 1,
                    ValorTotalRecebido: 0,
                    FormaRecebimento: 4,
                    DadosCartao: {
                                    ProcessarGateway: true,
                                    NumeroCartao: '',
                                    NomeTitular: '',
                                    InscricaoAtivaTitular: '',
                                    Expiracao: '',
                                    CVV: '',
                                    Bandeira: '',

                                },
                    }
                },



            dados_pagamento:{
                cpf_pagador: '',
                nome_cartao: '',
                numero_cartao: '',
                mes_vencimento: '',
                ano_vencimento: '',
                valor: 0,
                cod_transacao: 0,
                tipo: 'C',
                data: '', 
                cvv: '',
                contrato: 0,
                    mensagem_cielo: '',
                venda_aprovado: false,
                bandeira: '', 
            },

            rules: [
                value => !!value || 'Required.',
            // value => (value && value.length >= 3) || 'Min 3 characters',
            //required: value => !!value || 'Required.',
            //counter: value => value.length < 5 || 'Max 20 characters',
            ],



            rules_ano_cartao: {

                required: value => !!value || 'Campo Obrigatório',
                counter: value => value.length > 3 || '4 digitos'
                
            },

            select: null,
            vencimentos: [
                '01',
                '05',
                '15',
                '20',
                '25'

            ], 

            meses: [
                '01',
                '02',
                '03',
                '04',
                '05',
                '06',
                '07',
                '08',
                '09',
                '10',
                '11',
                '12'

            ], 


    }
},

mounted() {

    this.id_c_s = JSON.parse(sessionStorage.getItem('id_c_s'))

    this.lista_mensalidade()

},


methods:{



        openPagamento(item){
            this.dialog_card = true
            this.item_parcela = item
          
        },

        sendDataToAndroid() {
          const data = {
            valor: this.resposta_cielo.valor_pago,
            id: this.resposta_cielo.tid,
            nome: this.item_parcela.Cliente.Nome ,
            ref: this.item_parcela.Identificacao

          };

          // Enviar os dados para a Activity nativa do Android
          if (window.AndroidInterface && typeof window.AndroidInterface.sendData === 'function') {
            window.AndroidInterface.sendData(JSON.stringify(data));
          }

        },



        print_cielo () {

                  var id = (new Date()).getTime();
                  var divToPrint=document.getElementById('pnl').innerHTML;
                  
                    var myWindow = window.open('' + '?printerFriendly=true', id,
            "toolbar=1,scrollbars=1,location=0,statusbar=0,menubar=1,resizable=1,width=800,height=600,left = 240,top = 212");

                        myWindow.document.write('<html>'); 
                        myWindow.document.write('<body > <h1>Impressão VENDA ONLINE</h1> <br>'); 
                        myWindow.document.write(divToPrint); 
                        myWindow.document.write('</body></html>'); 
                        myWindow.print()

                    $.post("/ajax/friendlyPrintPage", postData).done(function(htmlContent) {
                        //myWindow.document.write(htmlContent);
                        myWindow.focus();
                    });


            /*
               // var divToPrint=document.getElementById('pnl').innerHTML;
                 var a = window.open('', '', 'height=500, width=500'); 
                  a.document.write('<html>'); 
                  a.document.write('<body > <h1>Impressão VENDA ONLINE</h1> <br>'); 
                 // a.document.write(divToPrint); 
                  a.document.write('</body></html>'); 
                  
                  a.print(); 
                  a.close(); 

                  */
                },
       
        unde(value){
            if (value === undefined) {
                              //  alert('false')
                return false
            }else{
                                                     // alert('true')

              return true
            }

        },


        pagar(){
            
           if (this.$refs.form.validate()) {
            this.processando = true
            this.msg_processando = 'Pagamento com Cartão de Crédito'


                this.model.parametros.IdDocumentoReceber = this.item_parcela.Id
                this.model.parametros.ValorTotalRecebido = this.item_parcela.ValorTotal
                this.model.parametros.DadosCartao.NumeroCartao = this.dados_pagamento.numero_cartao.replaceAll(' ', '');
                this.model.parametros.DadosCartao.NomeTitular = this.dados_pagamento.nome_cartao
                this.model.parametros.DadosCartao.InscricaoAtivaTitular = this.dados_pagamento.cpf_pagador
                this.model.parametros.DadosCartao.Expiracao = this.dados_pagamento.mes_vencimento +"/"+ this.dados_pagamento.ano_vencimento
                this.model.parametros.DadosCartao.CVV = this.dados_pagamento.cvv
                this.model.parametros.DadosCartao.Bandeira = this.dados_pagamento.bandeira
                    http.post('CaixaDIO/ReceberDocumento/', this.model, {
                                headers: authHeader()
                            }).then(response =>{

                                 this.msg_processando = 'Pagamento com Cartão de Crédito'
                                 this.processando = false
                                 this.dialog_card = false
                                 this.resposta_processamento = true
                                 this.resposta_cielo.msg_cielo = false
                                 this.resposta_cielo.sucesso_pagamento = false


                                //alert(response.data.ResponseData.StatusRetorno.Nome)

                                if (response.data.ResponseData.StatusRetorno.Id === 6 ||response.data.ResponseData.StatusRetorno.Nome === 'Transacao capturada com sucesso'){

                                    this.resposta_cielo.sucesso_pagamento = true
                                    this.resposta_cielo.tid = response.data.ResponseData.Tid
                                    this.resposta_cielo.dataRecebimento = response.data.ResponseData.DataRecebimento
                                    this.resposta_cielo.valor_pago = this.model.parametros.ValorTotalRecebido
                                    this.resposta_cielo.msg_cielo = response.data.ResponseData.StatusRetorno.Nome
                                    //this.dados_pagamento = {}

                                    this.lista_mensalidade()

                                } else {
                                    this.resposta_cielo.sucesso_pagamento = false
                                    this.resposta_cielo.tid = 0
                                    this.resposta_cielo.dataRecebimento = ''
                                    this.resposta_cielo.msg_cielo = response.data.ResponseData.StatusRetorno.Nome


                                }
                                })
                                .catch(function (error) {
                                   // alert(error)
                                    this.resposta_cielo.sucesso_pagamento = false
                                    this.resposta_cielo.tid = 0
                                    this.resposta_cielo.dataRecebimento = ''
                                    this.resposta_cielo.msg_cielo = 'Ops, Problemas na comunicação com o servidor'
                            });  

                            this.dados_pagamento.ano_vencimento = ''
                            this.$refs.form.reset();
                            this.dados_pagamento.ano_vencimento = ''




            }
           
        },
          
        ler_barras(item){
                this.parcela = item
                this.dialog_barras = true
        },
//http://api.grupoparque.cloud.ultrai.net/iVertexServicesQLD/CaixaDIO/ReceberDocumento
      ler_bandeira(){
          var cardnumber = this.dados_pagamento.numero_cartao.replace(/[^0-9]+/g, '');
            var cards = {
                Visa      : /^4[0-9]{12}(?:[0-9]{3})/,
                Master : /^5[1-5][0-9]{14}/,
                Diners    : /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
                Amex      : /^3[47][0-9]{13}/,
                Discover  : /^6(?:011|5[0-9]{2})[0-9]{12}/,
                Hipercard  : /^(606282\d{10}(\d{3})?)|(3841\d{15})/,
                Elo        : /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})/,
                JCB        : /^(?:2131|1800|35\d{3})\d{11}/,       
                Aura      : /^(5078\d{2})(\d{2})(\d{11})$/     
            };

            this.dados_pagamento.bandeira = 'Visa';

            for (var flag in cards) {
                if(cards[flag].test(cardnumber)) {
                    this.dados_pagamento.bandeira = flag;
                }
            }       

        //return ConsultaBandeira.bandeira(this.dados_pagamento.numero_cartao)
      },
      
       finalizar(){  
          
           this.resposta_processamento = false
           this.$router.push('/home')
        },

        lista_mensalidade(){
             http.get('DocumentoReceberDIO/ListarPorClienteEmAberto/', {
                headers: authHeader()
            }).then(response =>{

                    const lista_parcelas = response.data.ResponseData;

                    for ( let i = 0; i < lista_parcelas.length; i++){
                      const id_o = lista_parcelas[i].Origem.Id;
                      if(id_o == this.id_c_s.Id || id_o == this.id_c_s.IdVenda){
                        this.parcelas.push(lista_parcelas[i]);
                      }
                    }

                    this.onLoadDate = true

                })
                .catch(function (error) {

                });     
         },
        
 }

}

</script>

<style>
.outine-2 {
border: 2px solid white;
}

.card--flex-toolbar {
margin-top: -124px;
}

.learn-more-btn {
text-transform: initial;
text-decoration: underline;
}

.mobile {
color: #333;
}

@media screen and (max-width: 768px) {
.mobile table.v-table tr {
max-width: 100%;
position: relative;
display: block;
}

.mobile table.v-table tr:nth-child(odd) {
border-left: 6px solid deeppink;
}

.mobile table.v-table tr:nth-child(even) {
border-left: 6px solid cyan;
}

.mobile table.v-table tr td {
display: flex;
width: 100%;
border-bottom: 1px solid #f5f5f5;
height: auto;
padding: 10px;
}

.mobile table.v-table tr td ul li:before {
content: attr(data-label);
padding-right: .5em;
text-align: left;
display: block;
color: #999;

}
.v-datatable__actions__select
{
width: 50%;
margin: 0px;
justify-content: flex-start;
}
.mobile .theme--light.v-table tbody tr:hover:not(.v-datatable__expand-row) {
background: transparent;
}

}
.flex-content {
padding: 0;
margin: 0;
list-style: none;
flex-wrap: wrap;
width: 100%;
}

.flex-item {
padding: 5px;
height: 40px;
font-weight: bold;
}
</style>